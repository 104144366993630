<template>
  <v-container fluid>
    <v-row :align="contentAlign" :justify="contentJustify">
      <v-col cols="12" md="6" class="pa-0" style="height: 380px;">
        <v-img
          src="@/assets/img/enrollment-admin-login-bg.png"
        />
      </v-col>
      <v-col cols="12" md="6">
        <slot/>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'AuthLayout',
  props: {
    contentAlign: { type: String, default: 'center' },
    contentJustify: { type: String, default: 'center' },
  },
};
</script>

<style lang="scss">
  .enrollment-login-input {
    & input {
      text-align: center;
    }
  }
</style>
