<template>
  <AuthLayout>
    <v-form ref="form" lazy-validation v-model="valid">
      <v-row align="center" justify="center" class="ma-3">
        <v-img
          src="@/assets/logos/logo.svg"
          max-width="120"
          class="ma-7"
        />
      </v-row>
      <v-row align="center" justify="center">
        <h2>{{ $t('login.title') }}</h2>
      </v-row>
      <v-row justify="center">
        <div class="d-flex flex-column w-50">
          <v-btn
            min-width="200"
            class="mt-9 google-btn"
            :loading="loading"
            @click="googleSign"
          >
            <v-img
            src="@/assets/img/login-google-es.png"
            class="google-img"
          />
          </v-btn>
        </div>
      </v-row>
    </v-form>
  </AuthLayout>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import AuthLayout from '../layouts/AuthLayout.vue';

export default {
  name: 'LoginView',
  components: { AuthLayout },
  data: () => ({
    user: { email: '', password: '' },
    valid: true,
  }),
  mounted() {
    this.signOut();
  },
  computed: {
    ...mapState({
      loginError: ({ loginError }) => loginError,
      userLogged: ({ userLogged }) => userLogged,
      loading: ({ loading }) => loading,
    }),
    emailRule() {
      return [
        (v) => !!v || this.$t('login.email_required'),
        (v) => (v && this.validateEmail(v)) || this.$t('login.email_format_validation'),
      ];
    },
    passwordRule() {
      return [
        (v) => !!v || this.$t('login.password_required'),
      ];
    },
  },
  methods: {
    ...mapActions(['signIn', 'signOut']),
    async googleSign() {
      await this.signIn();
    },
    validateEmail(email) {
      return /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(email);
    },
  },
  watch: {
    loginError(nValue) {
      if (nValue) this.$toast.error(this.$t(`error.${nValue.error}`));
    },
    userLogged(nValue) {
      if (nValue) this.$router.push('/dashboard');
    },
  },
};
</script>

<style lang="scss">
  .enrollment-login-input {
    & input {
      text-align: center;
    }
  }
  .google-btn{
    max-width: 100px;
    width: 100px;
  }
  .google-img{
    max-width: 300px;
  }

</style>
